import React, { useEffect, useState } from "react";
import { useI18next } from "../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";
import { useFormikContext } from "formik";

import Arrow from "../../../assets/images/svg/expand-button.svg";
import {
    applyButton,
    clearButton,
    container,
    filtersBox,
    filtersExpandBox,
    generate,
    generateButton,
    mobileButtons,
    mobileHeader,
    buttonMobileHeader,
    noMobile,
    open,
    openButton,
    pressSpace,
    price,
    priceList,
    productList,
    saveButton,
    selectList,
    selects,
    leftSide,
    rightSide,
    selectInput,
    titleStyles,
    titleBig,
    saveButtonFilter,
    generateTip,
    arrowTop
} from "./generator-draw-fields.module.scss";
import FilterIcon from "../../../assets/images/svg/filters.svg";
import { breakpoints } from "../../../config/breakpoints";
import { fieldsAvailableToClear, priceFilters, selectFiltersConfigs } from "../config";
import {
    IGeneratorFormValues,
    TBlockedProduct,
    TGeneratorFilters,
} from "../../../models/generator.model";
import { IProduct } from "../../../models/product.model";
import { capitalizeFirstLetter } from "../../../utils/capitalize-first-letter";
import useMediaQuery from "../../../hooks/use-media-query";

import Select from "../../../components/atoms/form/select";
import ProductCard from "../molecules/product-card";
import Button from "../../../components/atoms/button";
import SwitchInput from "../../../components/atoms/form/switch-input";
import ButtonClose from "../../../components/atoms/button-close";
import ButtonWand from "../../../components/molecules/button-wand";
import ImageSlider from "../../../components/organisms/image-slider";

interface IGeneratorDrawFields {
    className?: string;
    filters: TGeneratorFilters;
    products: IProduct[];
    onSpaceKeydown?(): void;
    onSave?(): void;
}

const GeneratorDrawFields: React.FC<IGeneratorDrawFields> = ({
    className = "",
    filters,
    products,
    onSpaceKeydown,
    onSave,
}) => {
    const { t } = useI18next();
    const formik = useFormikContext<IGeneratorFormValues>();
    const isTablet = useMediaQuery(breakpoints.tablet);
    const [isOpen, setIsOpen] = useState(false);

    const handleOpenFilters = () => {
        setIsOpen(true);
    };

    const handleCloseFilters = () => {
        setIsOpen(false);
    };

    const handleSave = () => {
        if (typeof onSave !== "function") return;
        onSave();
    };

    const handleClear = () => {
        fieldsAvailableToClear.forEach((fieldKey) => {
            const clearedValue = Array.isArray(formik.values[fieldKey]) ? [] : "";
            formik.setFieldValue(fieldKey, clearedValue);
        });
    };

    const handleLockProduct = (product: IProduct) => () => {
        const lockedProducts = formik.values.blockedProducts;
        const lockedProductIds = lockedProducts.map((product) => product.productId);
        let newLockedProducts: TBlockedProduct[];
        if (lockedProductIds.includes(product.productId)) {
            newLockedProducts = lockedProducts.filter(
                (lockedProduct) => lockedProduct.productId !== product.productId
            );
        } else {
            newLockedProducts = [
                ...lockedProducts,
                { productId: product.productId, categoryGroup: product.categoryGroup },
            ];
        }
        formik.setFieldValue("blockedProducts", newLockedProducts);
    };

    useEffect(() => {
        const handleKeydown = (event: KeyboardEvent) => {
            if (event.code !== "Space") return;
            event.preventDefault();
            if (typeof onSpaceKeydown !== "function") return;
            console.log("Działam!");
            onSpaceKeydown();
        };
        document.addEventListener("keydown", handleKeydown);
        return () => {
            document.removeEventListener("keydown", handleKeydown);
        };
    }, []);

    return (
        <div className={`${container} ${className}`}>
            <div className={leftSide}>
                <div className={filtersExpandBox}>
                    <button type="button" onClick={handleOpenFilters} className={openButton}>
                        <FilterIcon /> {t("generator.button.filters")}
                    </button>
                    <Button
                        color="primary"
                        type="filled"
                        className={`${saveButton} ${saveButtonFilter}`}
                        onClick={handleSave}
                        htmlType="button"
                    >
                        {t("generator.button.save")}
                    </Button>
                </div>
                <div className={`${filtersBox} ${isOpen ? open : ""}`}>
                    <div className={mobileHeader}>
                        <p>{t("generator.filters.title")}</p>
                        <ButtonClose className={buttonMobileHeader} onClick={handleCloseFilters} />
                    </div>
                    <div className={titleStyles}>
                        <div className={titleBig}>
                            Stwórz<br />stylówkę
                        </div>
                    </div>
                    <div className={selects}>
                        {selectFiltersConfigs.map((config) => {
                            const { isMultiselect, selectClassName, filterKey } = config;
                            return (
                                <Select
                                    key={filterKey}
                                    className={`${selectClassName} ${selectInput}`}
                                    options={filters[filterKey]}
                                    name={filterKey}
                                    label={t(`generator.form.label.${filterKey}`)}
                                    placeholder={t(`generator.form.placeholder.${filterKey}`)}
                                    isMultiselect={isMultiselect}
                                    showCounter={isMultiselect}
                                    colorTheme="light"
                                    styleTheme="compact"
                                    selectText={isMultiselect ? "label" : "option"}
                                    listClassName={selectList}
                                />
                            );
                        })}
                        <Button
                            color="primary"
                            type="filled"
                            className={`${saveButton} ${noMobile}`}
                            htmlType="button"
                            onClick={handleSave}
                        >
                            {t("generator.button.save")}
                        </Button>
                    </div>
                    {isTablet && <div className={priceList}>
                        {priceFilters.map((filterKey) => {
                            return (
                                <SwitchInput
                                    key={filterKey}
                                    className={price}
                                    name={filterKey}
                                    options={filters[filterKey]}
                                    label={t(`generator.form.label.${filterKey}`)}
                                    showLabel={true}
                                />
                            );
                        })}
                    </div>}
                    <div className={mobileButtons}>
                        <Button
                            color="primary"
                            type="outlined"
                            className={clearButton}
                            htmlType="button"
                            onClick={handleClear}
                        >
                            {t("generator.button.clear")}
                        </Button>
                        <Button
                            color="beige"
                            type="filled"
                            className={applyButton}
                            htmlType="submit"
                            onClick={handleCloseFilters}
                        >
                            {t("generator.button.apply")}
                        </Button>
                    </div>
                </div>

            </div>
            <div className={rightSide}>
                {/* {!isTablet && <div className={priceList}>
                    {products.map((product) => {
                        const categoryPriceKey = `price${capitalizeFirstLetter(
                            product.categoryGroup
                        )}` as keyof TGeneratorFilters;
                        return (
                            <SwitchInput
                                key={categoryPriceKey}
                                className={price}
                                productLength={products.length}
                                name={categoryPriceKey}
                                options={filters[categoryPriceKey]}
                                label={t(`generator.form.label.${categoryPriceKey}`)}
                            />
                        );
                    })}
                </div>} */}
                {!isTablet && <div className={productList}>
                    {products.map((product) => {
                        const categoryPriceKey = `price${capitalizeFirstLetter(
                            product.categoryGroup
                        )}` as keyof TGeneratorFilters;
                        return (
                            <ProductCard
                                showInputs={true}
                                isTablet={isTablet}
                                key={product.productId}
                                product={product}
                                productLength={products.length}
                                onPadlockClick={handleLockProduct(product)}
                                isLocked={getIsLocked(formik.values.blockedProducts, product)}
                                classNameProduct={price}
                                nameProduct={categoryPriceKey}
                                optionsProduct={filters[categoryPriceKey]}
                                labelProduct={t(`generator.form.label.${categoryPriceKey}`)}
                            />
                        );
                    })}
                </div>}
                {isTablet && <>
                    <ImageSlider products={products} onSlideUp={onSpaceKeydown} />
                </>}
                <div className={generate}>
                    <div className={generateTip}>
                        <Arrow className={arrowTop} />
                        <p>Przesuń palcem w góre aby wyczarować dalej...</p>

                    </div>
                    <ButtonWand withLink={false} htmlType="submit" className={generateButton} text={t("generator.button.generate")}>
                    </ButtonWand>
                    {/* <p className={pressSpace}>{t("generator.press.space")}</p> */}
                </div>
            </div>
        </div>
    );
};

function getIsLocked(blockedProducts: TBlockedProduct[], product: IProduct) {
    return blockedProducts.map((product) => product.productId).includes(product.productId);
}

export default GeneratorDrawFields;
