import React from "react";

import {
    container,
    imgContainer,
    img,
    textFirst,
    textSecond,
    button,
    padlock,
    buttonContent,
} from "./product-card.module.scss";
import PadlockClosed from "../../../assets/images/svg/padlock-closed.svg";
import PadlockOpen from "../../../assets/images/svg/padlock-open.svg";
import { IProduct } from "../../../models/product.model";

import Link from "../../../components/atoms/link";
import SwitchInput from "../../../components/atoms/form/switch-input";

interface IProductCardProps {
    className?: string;
    onPadlockClick?: () => void;
    product: IProduct;
    productLength: number;
    isLocked?: boolean;
    nameProduct?: string;
    optionsProduct?: any[];
    labelProduct?: string;
    classNameProduct?: string;
    isTablet: boolean;
    showInputs: boolean;
}

const ProductCard: React.FC<IProductCardProps> = ({
    className = "",
    classNameProduct = "",
    onPadlockClick,
    product,
    productLength,
    isLocked,
    nameProduct = "",
    optionsProduct = [],
    labelProduct = "",
    isTablet,
    showInputs = false
}) => {
    const { category, imgUrl, url, affiliateUrl, brandName } = product;

    const handlePadlockClick = () => {
        if (typeof onPadlockClick === "function") {
            onPadlockClick();
        }
    };

    const handleLinkClick = (event: React.MouseEvent<HTMLDivElement>) => {
        console.log('handleLinkClick',event);
        if (event.target instanceof HTMLDivElement) {
            console.log('Clicked on a div element');
            const url = affiliateUrl || product.url;
            if (url) {
                window.open(url, '_blank');
            }
        }
        //href={affiliateUrl || url} target={'_blank'} 
    }

    return (
        <div onClick={(event) => handleLinkClick(event)} className={`${container} ${className}`}
            style={{ backgroundImage: `url(${imgUrl})`, flex: `0 0 ${100 / productLength}%` }}  >

            {!isTablet && showInputs && <SwitchInput
                key={product.productId}
                className={classNameProduct}
                productLength={productLength}
                name={nameProduct}
                options={optionsProduct}
                label={labelProduct}
            />}
            {
                !showInputs && <div></div>
            }
            {onPadlockClick && (
                <button onClick={handlePadlockClick} className={padlock} type="button">
                    {isLocked && <PadlockClosed />}
                    {!isLocked && <PadlockOpen />}
                </button>
            )}
            <div className={buttonContent}>
                <a href={affiliateUrl || url} className={button} target={'_blank'}>
                    <h2>Zobacz</h2>
                </a>
            </div>
        </div>
    );
};

export default ProductCard;
