import React, { useState, useRef, useCallback, useEffect } from "react";
import {
    sliderContainer,
    sliderContent,
    slide,
    navBtn,
    prev,
    next
} from "./image-slider.module.scss";
import { IProduct } from "../../models/product.model";

interface ImageSliderProps {
    products: IProduct[];
    onSlideUp?: () => void;
}

interface SlideProps {
    product: IProduct;
    index: number;
    currentIndex: number;
}

const handleLinkClick = (event: React.MouseEvent<HTMLDivElement>, product: IProduct) => {
    console.log('handleLinkClick', event);
    console.log('Clicked on a div element');
    const url = product.affiliateUrl || product.url;
    if (url) {
        window.open(url, '_blank');
    }
}

const Slide: React.FC<SlideProps> = React.memo(
    ({ product, index, currentIndex }) => (
        <div
            onClick={(event) => handleLinkClick(event, product)}
            className={`${slide} ${index === currentIndex ? "active" : ""}`}
            style={{ transform: `translateX(-${currentIndex * 100}%)`, backgroundImage: `url(${product.imgUrl})` }}
        >
            {/* <img src={src} alt={`Slide ${index}`} /> */}
        </div>
    )
);
const ImageSlider: React.FC<ImageSliderProps> = ({ products, onSlideUp }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const touchStartX = useRef(0);
    const touchStartY = useRef(0);
    const sliderRef = useRef<HTMLDivElement>(null);

    const nextSlide = useCallback(() => {
        setCurrentIndex((prev) => (prev === products.length - 1 ? 0 : prev + 1));
    }, [products.length]);

    const prevSlide = useCallback(() => {
        setCurrentIndex((prev) => (prev === 0 ? products.length - 1 : prev - 1));
    }, [products.length]);

    const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        touchStartX.current = e.touches[0].clientX;
        touchStartY.current = e.touches[0].clientY;
    };

    const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
        if (!sliderRef.current) return;

        const touchCurrentY = e.touches[0].clientY;
        const deltaY = touchStartY.current - touchCurrentY;

        // Blokuj przewijanie tylko gdy przesunięcie jest głównie pionowe
        if (Math.abs(deltaY) > 10) {
            e.preventDefault();
        }
    };

    const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
        const touchEndX = e.changedTouches[0].clientX;
        const touchEndY = e.changedTouches[0].clientY;
        const deltaX = touchStartX.current - touchEndX;
        const deltaY = touchStartY.current - touchEndY;

        if (deltaY > 50 && onSlideUp) {
            console.log("generate new");
            onSlideUp();
        } else if (Math.abs(deltaX) > Math.abs(deltaY)) {
            // Wykonuj przesuwanie w poziomie tylko jeśli ruch był bardziej poziomy niż pionowy
            if (deltaX > 50) {
                console.log("Swiped Left");
                prevSlide();
            } else if (deltaX < -50) {
                console.log("Swiped Right");
                nextSlide();
            }
        }
    };

    useEffect(() => {
        const currentSliderRef = sliderRef.current;
        if (currentSliderRef) {
            currentSliderRef.addEventListener('touchmove', handleTouchMove as any, { passive: false });
        }
        return () => {
            if (currentSliderRef) {
                currentSliderRef.removeEventListener('touchmove', handleTouchMove as any);
            }
        };
    }, []);

    return (
        <div
            ref={sliderRef}
            className={sliderContainer}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
        >
            <div className={sliderContent}>
                {products.map((product, index) => (
                    <Slide
                        key={index}
                        product={product}
                        index={index}
                        currentIndex={currentIndex}
                    />
                ))}
            </div>

            <button type="button" className={`${navBtn} ${prev}`} onClick={prevSlide}>
                &#10094;
            </button>
            <button type="button" className={`${navBtn} ${next}`} onClick={nextSlide}>
                &#10095;
            </button>
        </div>
    );
};

export default ImageSlider;
